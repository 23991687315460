.App {
  background-color: #333;
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  width: 100%;
}

p {
  color: white;
  margin: 0;
  margin-top: -16px;
}
